// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/compass/site/routes';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export const PATH_AFTER_LOGIN = paths.DASHBOARD;

export default function LoginButton({ sx }: Props) {
  return (
    <Button component={RouterLink} href={PATH_AFTER_LOGIN} variant="outlined" sx={{ mr: 1, ...sx }}>
      Login
    </Button>
  );
}

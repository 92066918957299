import { useMsal } from '@azure/msal-react';
import { useCasAuthenticated } from 'src/compass/shared/hooks/useUidParam';
// @mui
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// assets
import { paths } from 'src/compass/site/routes';
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import CasLink from 'src/compass/shared/components/CasLink/CasLink';
import { loginRequest } from 'src/compass/site/config';
import RenderError from './RenderError';

export default function View401() {
  const { instance } = useMsal();
  const activeAccount = instance?.getActiveAccount();

  const { isAuthenticated } = useCasAuthenticated();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleRetryButtonClick = () => {
    window.location.href = paths.home;
  };

  const handleLogout = () => instance.logoutRedirect();

  const getRenderAuthButton = () => {
    if (isAuthenticated)
      return (
        <>
          {activeAccount && activeAccount.username && (
            <Typography>{activeAccount.username}</Typography>
          )}
          <Stack gap="1rem" flexDirection="row" mt="1rem" alignItems="center">
            <Button onClick={handleRetryButtonClick} size="large" variant="contained">
              Go to Home
            </Button>
            <CasLink onClick={handleLogout}>Logout</CasLink>
          </Stack>
        </>
      );
    return (
      <Button onClick={() => handleLogin()} size="large" variant="contained">
        Re-Login
      </Button>
    );
  };

  return (
    <RenderError
      title="Not Authorized"
      description={
        <>
          The page you&apos;re trying access has restricted access.
          <br />
          Please contact to Site Administration.
        </>
      }
      illustration={SeverErrorIllustration}
      footer={getRenderAuthButton()}
    />
  );
}

import * as Yup from 'yup';

const isRoundedValue = (value: number, maxDecimalPlaces: number) => 
 Number(value.toFixed(maxDecimalPlaces)) === value;

export default function getMaximumDecimalSchema(maxDecimalPlaces: number = 2) {
  return Yup.number().test(
    'maxDecimals',
    `Field is limited to ${maxDecimalPlaces} decimal places.`,
    (value) => {
      if(value === undefined || value === null) return true;
      return isRoundedValue(value, maxDecimalPlaces);
    }
  );
}

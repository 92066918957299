import { Typography } from '@mui/material';
import CasLabel from '../CasLabel/CasLabel';

interface ICasDisplayField {
  label: string;
  value: string | JSX.Element;
  className?: string
}

export default function CasDisplayField({ label, value, className }: ICasDisplayField) {
  return (
    <CasLabel label={label} className={className} >
      <Typography>{value}</Typography>
    </CasLabel>
  );
}

// @mui
import { Stack, CircularProgress } from '@mui/material';

export default function CasLoader() {
  return (
    <Stack alignItems="center" p={3} height="100%" justifyContent="center">
      <CircularProgress color="primary" />
    </Stack>
  );
}

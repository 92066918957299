import SvgColor from "src/components/svg-color";

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const ICONS = {
  user: icon('ic_user'),
  dashboard: icon('ic_dashboard'),
};

import { DateFormat, formatDate, getDateInMoment } from 'src/compass/shared/util/casMoment';

type DateValuesProps = {
  numberOfMonths: number;
  dayOfMonth?: number;
};

interface DateOptionsProps extends DateValuesProps {
  format?: DateFormat;
}

export function useDateValues({ numberOfMonths, dayOfMonth = 1 }: DateValuesProps) {
  const dates = [];
  let currentDate = getDateInMoment();
  currentDate = currentDate.date(dayOfMonth);
   
  while (dates.length < numberOfMonths) {
    dates.push(formatDate(currentDate, DateFormat.YMD));
    currentDate = currentDate.add(1, 'month').startOf('month').date(dayOfMonth);
  }

  return dates;
}

export function useDateOptions({ format = DateFormat.MDY, numberOfMonths, dayOfMonth }: DateOptionsProps) {
  const dateValues = useDateValues({ numberOfMonths, dayOfMonth });

  const datesOptions = dateValues.map((date) => ({
    label: formatDate(date, format),
    value: date,
  }));

  return datesOptions;
}

import { useRetrieveEntityList } from 'src/compass/shared/hooks/reactQuery/useRetrieveEntityList';
import prependNoneOption from 'src/compass/shared/util/prependNoneOption';
import { CasRHFSelect } from 'src/compass/shared/components';

function useUserSelect() {
  const { data, isLoading } = useRetrieveEntityList({ entity: 'User/Select' });
  const options: any = prependNoneOption(data?.result || []);
  return [options, isLoading];
}

export default function UserSelect(props: any) {
  const [options, isLoading] = useUserSelect();
  return <CasRHFSelect {...props} loading={isLoading} options={options} />;
}

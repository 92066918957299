import { useIsAuthenticated } from "@azure/msal-react";
import { useSearchParams } from "react-router-dom";
import { getValidBigInt } from "../util/bigInt";

export default function useUidParam() {
  const [searchParams] = useSearchParams();
  const uidParam = searchParams.get('uid');
  return getValidBigInt(uidParam);
}

function pageName() {
    return window.location.pathname.split("/").pop();
}

function pageParamValue(paramName: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName); 
}
export function isPublicLRPQuotes() {
    const bigIntValue = getValidBigInt(pageParamValue('uid'));
    return pageName()?.toLowerCase() === 'lrp-quotes'
        && bigIntValue > 0;
}


export function useCasAuthenticated() {
    const isAuthenticated = useIsAuthenticated();
    const isWebuser = isPublicLRPQuotes();

    return {
        isWebuser,
        isAuthenticated: isAuthenticated && !isWebuser
    }
}

/*
    PublicLrpQuotesPage - is valid LRPQuotes request
    ApiContextProvider - passes to axiosAuthInstance
        -> shouldn't change per page but does
        -> could get from acquireAuthToken
    CasRouter - is valid LRPQuotes request

    acquireAuthToken - needs JS check can't have hook    
*/
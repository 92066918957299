import { Typography } from '@mui/material';

interface ICasLink {
  onClick: VoidFunction;
  children: any;
}

export default function CasLink({ onClick, children }: ICasLink) {
  return (
    <Typography
      sx={{ fontSize: 'inherit', cursor: 'pointer', color: 'primary.main' }}
      onClick={onClick}
      component="span"
    >
      {children}
    </Typography>
  );
}

import { lazy } from 'react';
import { ICONS } from '../navigationIcons';

const AppendToBasisPage = lazy(() => import('src/compass/pages/utilities/AppendToBasisPage'));
const DailyPasswordPage = lazy(() => import('src/compass/pages/utilities/DailyPasswordPage'));
const PipPage = lazy(() => import('src/compass/pages/utilities/PipPage'));
const ProprietaryAccountsPage = lazy(
  () => import('src/compass/pages/utilities/ProprietaryAccountsPage')
);
const PoManagementPage = lazy(() => import('src/compass/pages/utilities/PoManagementPage'));
const FeederIndexSetupPage = lazy(() => import('src/compass/pages/utilities/FeederIndexSetupPage'));

export const UtilitiesMenu = [
  {
    menuTitle: 'Utilities',
    path: 'utilities',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Append to Basis',
        path: 'appendtobasis',
        element: <AppendToBasisPage />,
        permission: 'UTILITIES_BASIS',
      },
      {
        title: 'Daily Password',
        path: 'dailypassword',
        element: <DailyPasswordPage />,
        permission: 'DAILY_PWD',
      },
      {
        title: 'Feeder Index Setup',
        path: 'feederindexsetup',
        element: <FeederIndexSetupPage />,
        permission: 'UTILITIES_FDR_IDX_SETUP',
      },
      {
        title: 'PIP',
        path: 'pip',
        element: <PipPage />,
        permission: 'UTILITIES_PIP',
      },
      {
        title: 'PO Management',
        path: 'pomanagement',
        element: <PoManagementPage />,
        permission: 'UTILITIES_PO_MGT',
      },
      {
        title: 'Proprietary Accounts',
        path: 'proprietaryaccounts',
        element: <ProprietaryAccountsPage />,
        permission: 'UTILITIES_PROPRIETARY',
      },
    ],
  },
];

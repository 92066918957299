import { CasRHFSelect, CasSelect, componentProps, CasEntitySelectProps } from 'src/compass/shared/components';

import { Entity } from 'src/compass/site/util';
import useRetrieveEntityListOptions, {
  EntityListOptionsProps,
} from 'src/compass/shared/hooks/reactQuery/useRetrieveEntityListOptions';

function useRiskManagerOptions({ includeAllOption, includeNoneOption }: EntityListOptionsProps) {
  return useRetrieveEntityListOptions({
    entity: Entity.EMPLOYEE_RISK_MANAGER,
    includeAllOption,
    includeNoneOption,
  });
}

export function CasRHFRiskManagerSelect({
  includeAllOption,
  includeNoneOption,
  ...rest
}: CasEntitySelectProps) {
  const { options, isLoading } = useRiskManagerOptions({ includeAllOption, includeNoneOption });
  return <CasRHFSelect loading={isLoading} options={options} {...rest} />;
}

export function CasRiskManagerSelect({
  includeAllOption,
  includeNoneOption,
  ...rest
}: componentProps) {
  const { options } = useRiskManagerOptions({ includeAllOption, includeNoneOption });
  return <CasSelect options={options} optionsLabelField="name" optionsValueField="id" {...rest} />;
}

import { Box, BoxProps } from '@mui/material';
import ICasRenderCellProps from '../ICasRenderCellProps';

interface ArrayAsRowsProps extends ICasRenderCellProps {
  textAlign?: BoxProps['textAlign'];
}

export function CasRenderArrayAsRows({ row, column, textAlign }: ArrayAsRowsProps) {
  return row[column.field]?.map((item: string, index: number) => <Box key={`${index}${item}`} textAlign={textAlign}>{item}</Box>);
}

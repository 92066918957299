import { useRetrieveEntityList } from 'src/compass/shared/hooks/reactQuery/useRetrieveEntityList';
import prependNoneOption from 'src/compass/shared/util/prependNoneOption';
import { CasEntitySelectProps, CasRHFSelect } from 'src/compass/shared/components';

function useAgencySelect() {
  const { data, isLoading } = useRetrieveEntityList({ entity: 'Ins/Agency/Select' });
  const options: any = prependNoneOption(data?.result || []);
  return [options, isLoading];
}

export default function AgencySelect(props: CasEntitySelectProps) {
  const [options, isLoading] = useAgencySelect();
  return <CasRHFSelect {...props} loading={isLoading} options={options} />;
}

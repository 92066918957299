import moment from 'moment';
import 'moment-timezone';

export enum DateFormat {
  MDY = 'MM/DD/YYYY',
  MDY_dddd = 'MM/DD/YY dddd',
  MYM = 'MM/YYYY (MMM)',
  YMD = 'YYYY-MM-DD',
  MMMMY = 'MMMM YYYY',
  MMM_YY = 'MMM YY',
  MD = 'MM/DD',
  MMMY=  'MMM YYYY',
  MDY_HM = 'MM/DD/YYYY HH:mm',
  MMM_D_YYYY = 'MMM D, YYYY',
  ddd_M_D= 'ddd M/D',
  M_D_YY_ddd= 'M/D/YY (ddd)'
} 

export function getDateInMoment(dateString?: moment.MomentInput) {
  return moment(dateString);
}

export function getDateMonth(dateString?: moment.MomentInput) {
  // months are 0-indexed, so added 1 to get the correct month number
  return moment(dateString).month() + 1;
}

export function getDateYear(dateString?: moment.MomentInput) {
  return moment(dateString).year();
}

export function getDateDuration(dateString1?: moment.MomentInput, dateString2?: moment.MomentInput) {
  const dateM1 = getDateInMoment(dateString1);
  const dateM2 = getDateInMoment(dateString2);
  const diff = dateM1.diff(dateM2);
  return moment.duration(diff);
}

export function isSameDayInDateList (date: moment.MomentInput, dateList: moment.Moment[]) {
    return dateList.some((listDate) => listDate.isSame(moment(date), 'day'));
};

export function getRelativeTime(date: moment.MomentInput) {
  return moment.utc(date).fromNow();
}

export function formatDate(date: moment.MomentInput, dateFormat: DateFormat) {
  if(!date) return ''
  return moment(date).format(dateFormat);
}

export function formatDateTime(dateTime: moment.MomentInput, dateFormat: string) {
  if (!dateTime) return '';
  return moment.utc(dateTime).tz("America/Denver").format(dateFormat);
}
 
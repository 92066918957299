import { Typography, Box } from '@mui/material';

type QuotesFooter = {className: string}

export default function LrpQuotesFooter({className}: QuotesFooter) {
  return (
    <Box>
      <Typography mt={4} fontSize={12} fontStyle='italic'>
        This is a quote only. Cost CWT includes subsidy and assumes the producer is eligible for
        subsidy. Call your Compass risk management consultant for details. Coverage & price are
        available on the Quote Date until 7am MST the following day, and are not bound until
        approved by the RMA.
      </Typography>
    </Box>
  );
}

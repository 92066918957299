// @mui
import { Theme, SxProps } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

type Props = {
  loading: boolean;
  sx?: SxProps<Theme>;
};

export default function TableCircularProgress({ loading, sx }: Props) {
  return (
    <TableRow>
      {loading ? (
        <TableCell colSpan={12} sx={{ textAlign: 'center', py: 15 }}>
          <CircularProgress title="Loading..." sx={sx} />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

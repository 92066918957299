import { Helmet } from 'react-helmet-async';
// sections
import { CasPageContent } from 'src/compass/shared/components';
import HomeView from './components/HomeView';
// ----------------------------------------------------------------------

export default function Home() {
  return (
    <>
      <Helmet>
        <title> Dashboard</title>
      </Helmet>

      <CasPageContent>
        <HomeView />
      </CasPageContent>
    </>
  );
}

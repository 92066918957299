import { useMemo } from 'react';
import { TableCell, TableFooter, TableRow } from '@mui/material';
import { IGeneralObjectType } from 'src/compass/site/util';

import ICasTableColumn from '../ICasTableColumn';
import CasTableCell from './CasTableCell';

export type CasTableFooterProps = {
  rowData: IGeneralObjectType[];
  columns: ICasTableColumn[];
};

function calculateTotalsOfColumnValues(dataRows: IGeneralObjectType[], columns: ICasTableColumn[]) {
  return columns.reduce((totals: IGeneralObjectType, column) => {
    if (column.total) {
      const total = dataRows.reduce((sum, row) => {
        const value = parseFloat(row[column.field]) || 0; // Handle NaN
        return sum + value;
      }, 0);
      totals[column.field] = total;
    }
    return totals;
  }, {});
}

/* 
    If the first column have total=false, 'TOTAL' label will be displayed in first column.
    If the first column does have total=true, no TOTAL label is displayed, we will display total value.
*/

export default function CasTableFooter({ rowData = [], columns }: CasTableFooterProps) {
  const columnTotals = useMemo(
    () => calculateTotalsOfColumnValues(rowData, columns),
    [rowData, columns]
  );

  const shouldDisplayTotalRow = rowData.length > 1 && columns.some((column) => column.total);

  if (shouldDisplayTotalRow)
    return (
      <TableFooter>
        <TableRow>
          {columns[0]?.total ? (
            <CasTableCell row={columnTotals} column={columns[0]} />
          ) : (
            <TableCell sx={{ textAlign: 'right' }}>TOTAL</TableCell>
          )}
          {columns.slice(1).map((column) => (
            <CasTableCell key={column.field} row={columnTotals} column={column} />
          ))}
        </TableRow>
      </TableFooter>
    );

  return null;
}

import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { formatDate, getDateInMoment, DateFormat } from 'src/compass/shared/util/casMoment';
import CasLabel from '../CasLabel/CasLabel';

// ----------------------------------------------------------------------

export default function CasDatePicker({
  name,
  handleDateChange,
  helperText,
  type,
  label = null,
  minDate,
  ...other
}: any) {
  const { control } = useFormContext();
  return (
    <CasLabel label={label}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              sx={{ maxWidth: 160 }}
              value={value ? getDateInMoment(value) : null}
              onChange={(date: moment.Moment | null) => {
                if (date?.isValid()) {
                  const formattedDate = formatDate(date, DateFormat.YMD);
                  onChange(formattedDate);
                  if (handleDateChange) handleDateChange(formattedDate);
                } else {
                  onChange(null); // Handle clearing the date
                }
              }}
              label=""
              format="MM/DD/YYYY"
              inputProps={{ readOnly: true }} // Added the readOnly as we don't want the user to write anything in datepicker.
              minDate={minDate ? getDateInMoment(minDate) : null}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error ? error?.message : helperText,
                },
              }}
              {...other}
            />
          </LocalizationProvider>
        )}
      />
    </CasLabel>
  );
}

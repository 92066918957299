import { Helmet } from 'react-helmet-async';
// sections
import { CasPageContent } from 'src/compass/shared/components';
import View401 from './components/View401';

export default function Page401() {
  return (
    <>
      <Helmet>
        <title>Not Authorized</title>
      </Helmet>
      <CasPageContent>
        <View401 />
      </CasPageContent>
    </>
  );
}

import { Stack } from '@mui/material';
// types
import ICasRenderCellProps from '../ICasRenderCellProps';
import { CasRenderCurrency } from './CasRenderCurrency';
import CasRenderAsOfDate from './CasRenderAsOfDate';

/*
    Display as of date with its data.
*/

export function CasRenderAsOfCurrency({ row, column }: ICasRenderCellProps) {
  return (
    <Stack>
      <CasRenderAsOfDate row={row} />
      {CasRenderCurrency({ row, column })}
    </Stack>
  );
}

import { InputAdornment } from '@mui/material';
import { CasRHFTextField } from '..';

export default function CasPercentField(props: any) {
  const labelLength = props?.label?.length ?? 0;

  return (
    <CasRHFTextField
      {...props}
      type="number"
      fullWidth={false}
      sx={{ maxWidth: Math.max(110, labelLength * 7), pr: 0 }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        inputProps: { style: { textAlign: 'right' } },
      }}
    />
  );
}

import './CasMRTableTitle.scss';

import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import CasIconButton from '../CasIconButton/CasIconButton';
import { CasStackRow } from '../CasStack/CasStackRow';

type CasMRTableTitleProps = {
  title: string;
  isFullScreen: boolean;
  onModalOpen: Function;
  children: ReactNode;
};

export default function CasMRTableTitle({
  title,
  isFullScreen,
  onModalOpen,
  children,
}: CasMRTableTitleProps) {
  return (
    <CasStackRow
      flexGrow={1}
      justifyContent={isFullScreen ? 'flex-end' : 'flex-start'}
      gapSize='large'
      className='CasMRTable-title'
    >
      {isFullScreen && <Typography variant="h5">{title}</Typography>}
      {children}
      <CasIconButton
        icon="material-symbols:edit"
        onClick={() => onModalOpen(true)}
        height={24}
        width={24}
        tooltip="Edit"
      />
    </CasStackRow>
  );
}

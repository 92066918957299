// assets
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import { CasButton } from 'src/compass/shared/components';
import { paths } from 'src/compass/site/routes';
import RenderError from './RenderError';

export default function View500() {
  const handleRetryButtonClick = () => {
    window.location.href = paths.home;
  };

  return (
    <RenderError
      title="There was a problem accessing the server."
      illustration={SeverErrorIllustration}
      footer={
        <CasButton size="large" color="inherit" onClick={handleRetryButtonClick}>
          Retry
        </CasButton>
      }
    />
  );
}

import { Box } from '@mui/material';
import { formatDate, DateFormat } from 'src/compass/shared/util/casMoment';

enum AsOfColor {
  Edit = '#ECF7FE',
  Add = '#F1FEEB',
  Disabled = '#EBEBEB',
  RateColor = '#f15bf1',
  DeleteColor = '#00B8D9',
}

export default function CasRenderAsOfDate({ row }: any) {
  return (
    row?.displayAsOfDate && (
      <Box
        sx={{
          fontSize: 'inherit',
          color: AsOfColor.RateColor,
          fontWeight: 'bold',
          textAlign: 'right',
        }}
      >
        {formatDate(row?.asOfDate, DateFormat.MD)}
      </Box>
    )
  );
}

import { ReactNode } from 'react';
// @mui
import Box from '@mui/material/Box';

interface ICasTabsPanel {
  value: string;
  index: string;
  children: ReactNode;
}

const CasTabsPanel = ({ value, index, children }: ICasTabsPanel) => (
  <div>{value === index && <Box>{children}</Box>}</div>
);

export default CasTabsPanel;

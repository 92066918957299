import { FormControl, InputLabel } from '@mui/material';

interface ICasControl {
  label: string;
  children: JSX.Element;
  shrink?: boolean;
}

export default function CasControl({ label, children, shrink }: ICasControl) {
  if (label === null) return children;
  return (
    <FormControl
      sx={{
        flexShrink: 0,
      }}
    >
      <InputLabel shrink={shrink}>{label}</InputLabel>
      {children}
    </FormControl>
  );
}

import { useEffect, useRef } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';

interface ICasRHFUpload {
  name: string;
  accept: string;
}

export default function CasRHFUpload({ name, accept }: ICasRHFUpload) {
  const { control } = useFormContext();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const selectedFile = useWatch({ control, name });

  useEffect(() => {
    if (!selectedFile && fileRef.current) fileRef.current.value = '';
  }, [selectedFile]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref, ...rest }, fieldState: { error } }) => (
        <div>
          <input
            type="file"
            accept={accept}
            ref={fileRef}
            onChange={(e) => {
              if (e.target.files) onChange(e.target.files[0]);
            }}
            {...rest}
          />
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

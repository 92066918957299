// @mui
import { TextFieldProps } from '@mui/material/TextField';
// component
import { CasRHFTextField } from '..';

type Props = TextFieldProps & {
  name: string;
};

export default function CasRHFMultilineTextField(props: Props) {
  return <CasRHFTextField multiline minRows={2} {...props} />;
}

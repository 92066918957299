import { useMemo } from 'react';

// components
import {
  CasEditForm,
  CasRHFTextField,
  CasRHFSwitch,
  IEditForm,
} from 'src/compass/shared/components';

// schema
import { Entity } from 'src/compass/site/util';
import { BranchSchema } from './BranchSchema';
import AgencySelect from './AgencySelect';
// ----------------------------------------------------------------------

export default function BranchForm({ row, onSaved }: IEditForm) {
  const { id } = row || {};

  const defaultValues = useMemo(
    () => (id ? row : { isActive: true, isMainOffice: false }),
    [id, row]
  );

  return (
    <CasEditForm
      defaultValues={defaultValues}
      onSaved={onSaved}
      entity={Entity.BRANCH}
      schema={BranchSchema}
    >
      <CasRHFTextField name="name" label="Branch" />
      <AgencySelect name="insAgencyId" label="Agency" />
      <CasRHFTextField name="cmePayrollExportCode" label="CME Payroll Export Code" />
      <CasRHFTextField name="eHarvestAliasUser" label="eHarvest User" />
      <CasRHFSwitch name="isMainOffice" label="Is Main Office?" />
      <CasRHFSwitch name="isActive" label="Active" />
    </CasEditForm>
  );
}

/*
  Transforms a camelCase string into a human-readable name.
  ex. dateOpen => Date Open
*/

export default function formatCamelCaseAsTitle(field: string) {
  return field
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

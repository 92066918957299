import ICasRenderCellProps from '../ICasRenderCellProps';

interface ICasRenderSplitStringArrayCellProps extends ICasRenderCellProps {
  separator?: string;
}

export function CasRenderSplitStringArray({
  row,
  column,
  separator = ',  ',
}: ICasRenderSplitStringArrayCellProps) {
  return row[column.field]?.split(separator)?.map((item: string) => <div>{item}</div>);
}

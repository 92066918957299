import { useMemo } from 'react';
import {
  useMaterialReactTable,
  type MRT_RowData,
  type MRT_TableOptions,
} from 'material-react-table';
import { UseQueryResult } from '@tanstack/react-query';
import { getDefaultMRTableOptions } from '../util/getDefaultMRTableOptions';
import generateColumnsFromData from '../util/generateColumnsFromData';
import { ModifiedMRT_ColumnDef } from '../components/CasMRTable/CasMRTable';

type useCasMRTableProps = Partial<MRT_TableOptions<MRT_RowData>> & {
  queryResult?: UseQueryResult<Record<string, any>, any>;
  data?: MRT_RowData[];
  columns?: ModifiedMRT_ColumnDef<MRT_RowData>[];
  createColumnsFromData?: boolean;
  overrideColumns?: Record<string, Record<string, any>>;
};

export const useCasMRTable = ({
  queryResult,
  data: dataFromProp,
  columns,
  createColumnsFromData,
  overrideColumns,
  initialState,
  ...rest
}: useCasMRTableProps) => {
  if (createColumnsFromData && columns)
    throw Error("It's not valid to specify both createColumnsFromData and columns properties.");

  if (!createColumnsFromData && overrideColumns)
    throw Error(
      "It's not valid to specify overrideColumns without also specifying createColumnsFromData."
    );

  const { isLoading, isError, data: dataFromQuery } = queryResult || {};

  const memoizedColumns = useMemo(
    () =>
      createColumnsFromData
        ? generateColumnsFromData(dataFromProp || dataFromQuery?.result, overrideColumns)
        : columns || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataFromProp, dataFromQuery]
  );

  return useMaterialReactTable({
    ...getDefaultMRTableOptions({
      isLoading: isLoading ?? false,
      isError: isError ?? false,
      initialState,
    }),
    /*
    When this is enabled & fullscreen mode, app bar is on top
    muiTablePaperProps: {
      elevation: 0,       
      sx: {
        borderRadius: '0',
        border: 'none'
      },
    }, 
    */
    columns: memoizedColumns,
    data: dataFromProp || dataFromQuery?.result || [],
    ...rest,
  });
};

import { ReactNode } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Label from 'src/components/label';

export interface TabItem {
  children: ReactNode;
  label: string;
  count?: number;
  className?: string
}
interface ICasTabs {
  value: string;
  handleTabchange: (_: React.SyntheticEvent, newValue: any) => void;
  tabList: TabItem[];
}

const CasTabs = ({ value, handleTabchange, tabList }: ICasTabs) => (
  <Tabs
    value={value}
    onChange={handleTabchange}
    sx={{
      px: 2.5,
      mb: 1,
      boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
    }}
  >
    {tabList.map((tab) => {
      if (tab.count)
        return (
          <Tab
            key={tab.label}
            value={tab.label}
            label={tab.label}
            iconPosition="end"
            icon={<Label color="warning">{tab.count}</Label>}
            className={tab.className}
          />
        );
      return <Tab key={tab.label} value={tab.label} label={tab.label} className={tab.className} />;
    })}
  </Tabs>
);

export default CasTabs;

import React from 'react';
import { Navigate } from 'react-router-dom';
// Msal imports
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import CasLoader from 'src/compass/shared/components/CasLoader/CasLoader';
import { loginRequest } from 'src/compass/site/config';
import { paths } from 'src/compass/site/routes';

interface AuthenticatedComponentProps {
  Component: any;
}

const CasAuthenticationHOC: React.FC<AuthenticatedComponentProps> = ({ Component }) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={() => <Navigate to={paths.page401} />}
      loadingComponent={CasLoader}
    >
      {Component}
    </MsalAuthenticationTemplate>
  );
};

export default CasAuthenticationHOC;

import React from 'react';
// @mui
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// component
import Iconify from 'src/components/iconify';

interface ICasIconButton {
  icon: string | JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  height?: number;
  width?: number;
  visibility?: 'inherit' | 'hidden';
  display?: 'inherit' | 'none';
  color?:
    | 'inherit'
    | 'default'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  tooltip?: string;
}

const StyledIconButton = styled(IconButton)<{
  visibility: 'inherit' | 'hidden';
  display: 'inherit' | 'none';
}>(
  {
    width: 40,
    height: 40,
    padding: 0,
    opacity: 0.48,
    '&:hover': { opacity: 1 },
  },
  ({ visibility, display }) => ({
    visibility,
    display,
  })
);

export default function CasIconButton({
  icon,
  onClick,
  disabled = false,
  width = 25,
  height = 25,
  visibility = 'inherit',
  display = 'inherit',
  color = 'inherit',
  tooltip = '',
}: ICasIconButton) {
  const iconElement =
    typeof icon === 'string' ? (
      <Iconify icon={icon} width={width} height={height} />
    ) : (
      React.cloneElement(icon, {
        style: { width, height, fontSize: width, color },
      })
    );

  return (
    <Tooltip title={tooltip} disableHoverListener={!tooltip}>
      <StyledIconButton
        color={color}
        onClick={onClick}
        disabled={disabled}
        visibility={visibility}
        display={display}
      >
        {iconElement}
      </StyledIconButton>
    </Tooltip>
  );
}

import { ChangeEvent, useState } from 'react';
import { useFormContext, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import formatWithConditionalDecimals from 'src/compass/shared/util/formatWithConditionalDecimals';

// ----------------------------------------------------------------------

type Props = Omit<TextFieldProps, 'onChange'> & {
  name: string;
  isCurrencyField?: boolean;
  emptyValueToNull?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: any) => void;
};

/* updated RHF-text field component as that component have check which don't allow writing 0
        value={type === 'number' && field.value === 0 ? '' : field.value}
*/

/**
 * @param emptyValueIToNull boolean
 * If true, the field will default to null when cleared.
 * If false, it will default to an empty string.
 */

export default function CasRHFTextField({
  name,
  isCurrencyField = false,
  emptyValueToNull = false,
  helperText,
  type,
  onChange,
  ...other
}: Props) {
  const { control } = useFormContext();

  const [isFocused, setIsFocused] = useState(false);

  const handleBlur =
    (field: ControllerRenderProps<FieldValues, string>) =>
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      field.onBlur();
      if (isCurrencyField && event.target.value) {
        const numberValue = formatWithConditionalDecimals(event.target.value);
        field.onChange(numberValue);
      }
    };

  const standardizeValue = (value: any) => {
    if(value === null) return null;
    if (type === 'number') return value !== '' ? Number(value) : null;
    if (emptyValueToNull) return value?.trim() !== '' ? value : null;
    return value ?? '';
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={emptyValueToNull ? null : undefined}
      render={({ field, fieldState: { error } }) => {
        let displayValue = standardizeValue(field.value);
        
        if (isCurrencyField && !isFocused)
          displayValue = formatWithConditionalDecimals(field.value);

        return (
          <TextField
            {...field}
            fullWidth
            type={type}
            value={displayValue}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur(field)}
            onChange={(event) => {
              const processedValue = standardizeValue(event.target.value);
              field.onChange(processedValue);
              if (onChange) onChange(event, processedValue);
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
}

import { getEmailSchema, getNullableIntegerSchema, getSelectSchema, getStringSchema } from 'src/compass/shared/schemas';
import * as Yup from 'yup';

export const EmployeeSchema = Yup.object().shape({
  userId: getSelectSchema('User').notRequired(),
  shortName: Yup.string().required('Short name is required'),
  branchId: Yup.mixed<any>().nullable().required('Branch is required'),
  docusignUser: getStringSchema(50, 'Docusign User').notRequired(),
  sceEntryCCEmail: getEmailSchema('SCE CC').notRequired(),
  payrollEmpNbr: getNullableIntegerSchema("Payroll Key (Emp#)")
    .transform((value, originalValue) => {
      if (originalValue === null || originalValue === undefined || originalValue === '') return 0;
      return value;
    })
    .default(0),
  isRiskManager: Yup.boolean(),
  isSalesOrOther: Yup.boolean(),
  isActive: Yup.boolean(),
});

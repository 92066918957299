/* eslint-disable  @typescript-eslint/no-shadow */

// Define the type for the input JSON objects. Since the structure is not known, we'll use a generic type.
type GenericObject = { [key: string]: any };

// Helper function to extract string values from an object
function extractStringValues(obj: GenericObject): string[] {
  const values: string[] = [];

  function extract(obj: GenericObject): void {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        values.push(value.Display);
      } else if (value !== null) {
        values.push(String(value)); // Push the string value
      }
    });
  }

  extract(obj);
  return values;
}

function insertStringValue(obj: GenericObject, targetStr: string[]): void {
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== 'AsOfDate' && value !== null) {
      if (typeof value === 'object') {
        const tempToStr = extractStringValues(value);
        targetStr.push(`${key}: ${tempToStr.join(' \n')}`);
      } else {
        targetStr.push(`${key}: ${String(value)}`);
      }
    }
  });
}

export function compareJSONObjects(
  fromJSON: GenericObject,
  toJSON: GenericObject
): { fromStr: string[]; toStr: string[] } {
  const fromStr: string[] = [];
  const toStr: string[] = [];

  // Check if fromJSON is empty and toJSON is not, then we have a new object
  if (Object.keys(fromJSON).length === 0 && Object.keys(toJSON).length > 0) {
    fromStr.push('(new)');
    insertStringValue(toJSON, toStr);
    return { fromStr, toStr };
  }

  // Otherwise, compare objects normally
  Object.keys({ ...fromJSON, ...toJSON }).forEach((key) => {
    if (key === 'AsOfDate') {
      return;
    }

    const fromValue = fromJSON[key];
    const toValue = toJSON[key];

    if (typeof fromValue === 'object' && typeof toValue === 'object') {
      if (JSON.stringify(fromValue) !== JSON.stringify(toValue)) {
        // when toValue/fromValue contains null value
        if (fromValue) {
          const tempFromStr = extractStringValues(fromValue);
          fromStr.push(`${key}: ${tempFromStr.join(' \n')}`);
        }
        if (toValue) {
          const tempToStr = extractStringValues(toValue);
          toStr.push(`${key}: ${tempToStr.join(' \n')}`);
        }
      }
    } else if (fromValue !== toValue) {
      if (fromValue) fromStr.push(`${key}: ${String(fromValue)}`);
      if (toValue) toStr.push(`${key}: ${String(toValue)}`);
    }
  });
  return { fromStr, toStr };
}

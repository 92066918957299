import { Box } from '@mui/material';
import ICasRenderCellProps from '../ICasRenderCellProps';

export function CasRenderCurrency({ row, column }: ICasRenderCellProps) {
  // Format the currency value using Intl.NumberFormat
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

  // Get the value from the row object using the column field
  const value = row[column.field];

  return value != null && <Box textAlign="right">{formatCurrency(value)}</Box>;
}

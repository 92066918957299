import { useEffect, ReactNode, FormEvent, SetStateAction, Dispatch } from 'react';
import { FormProvider, Mode, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';

import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';

import { IGeneralObjectType } from 'src/compass/site/util';
import CasLoaderWithBackdrop from '../CasLoader/CasLoaderWithBackdrop';

export type FormMethodType = UseFormReturn<any> | null;

interface ICasReactHookFormProps {
  children: ReactNode;
  schema: ObjectSchema<any>;
  defaultValues?: IGeneralObjectType;
  setFormMethods?: Dispatch<SetStateAction<FormMethodType>>;
  onSubmit?: SubmitHandler<any>;
  mode?: Mode;
}

export default function CasReactHookForm({
  children,
  schema,
  defaultValues = {},
  setFormMethods,
  onSubmit: onSubmitFromParent = () => {},
  mode = 'onChange',
}: ICasReactHookFormProps) {
  
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (setFormMethods) {
      setFormMethods(methods);
    }
  }, [methods, setFormMethods]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    /* 
      Need to stopPropagation if form is embedded in another form in the
      React tree.  e.g. Dialog with form that is embedded in an entry form.
    */
    e.stopPropagation();
    handleSubmit(onSubmitFromParent)(e);
  };

  return (
    <FormProvider {...methods}>
      {isSubmitting && <CasLoaderWithBackdrop />}
      <Stack>
        <form onSubmit={onSubmit}>{children}</form>
      </Stack>
    </FormProvider>
  );
}

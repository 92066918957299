// utils
import { compareJSONObjects } from 'src/compass/shared/util/compareJSONObjects';
// types
import ICasRenderCellProps from '../ICasRenderCellProps';

export function CasRenderChanges({ row, column }: ICasRenderCellProps) {
  const { fromStr, toStr } = compareJSONObjects(JSON.parse(row.fromJson), JSON.parse(row.toJson));
  return (column.field === 'from' ? fromStr : toStr).map((item: string, index: number) => (
    <div key={index}>{item}</div>
  ));
}

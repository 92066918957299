import { Button } from '@mui/material';

export interface ICasDeleteButton {
  onClick: (event: React.MouseEvent<any>) => void;
  deleteLabelSuffix?: string;
}

export default function CasDeleteButton({ onClick, deleteLabelSuffix="" }: ICasDeleteButton) {
  return (
    <Button
      sx={{
        fontWeight: "normal"
      }}
      color='error'
      variant="text"
      onClick={onClick}
    >
      Delete {deleteLabelSuffix}
    </Button>
  );
}

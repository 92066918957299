import CasDisplayField from './CasDisplayField';

interface CasDocumentationFieldProps {
  value: string | JSX.Element;
  label?: string;
  className?: string
}

export default function CasDocumentationField({label = 'Documentation', className='CasDocumentationField', value}: CasDocumentationFieldProps) {
  if (!value || (typeof value === 'string' && value.trim() === ""))
    return null;

  return <CasDisplayField className={className} label={label}  value={value}/>;
}

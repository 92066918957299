import { lazy } from 'react';
import { ICONS } from '../navigationIcons';

const SettingPage = lazy(() => import('src/compass/pages/commissions/SettingsPage'));
const LrpCommissionsPage = lazy(() => import('src/compass/pages/commissions/LrpCommissionsPage'));
const CmeCommissionsPage = lazy(() => import('src/compass/pages/commissions/CmeCommissionsPage'));
const MonthlyProcessingPage = lazy(
  () => import('src/compass/pages/commissions/MonthlyProcessingPage')
);
const CommissionsDataPage = lazy(() => import('src/compass/pages/commissions/CommissionsDataPage'));
const LrpDataPage = lazy(() => import('src/compass/pages/commissions/LrpDataPage'));
const MyCommissionsPage = lazy(() => import('src/compass/pages/commissions/MyCommissionsPage'));

export const CommissionsMenu = [
  {
    menuTitle: 'Commissions',
    path: 'commissions',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'My Commissions',
        path: 'mycommissions',
        element: <MyCommissionsPage />,
        permission: 'MY_COMMISSIONS',
      },
      {
        title: 'LRP Data',
        path: 'lrpdata',
        element: <LrpDataPage />,
        permission: 'LRP_DATA',
      },
      {
        title: 'Data',
        path: 'data',
        element: <CommissionsDataPage />,
        permission: 'COMMISSIONDATA',
      },
      {
        title: 'Monthly Processing',
        path: 'monthlyprocessing',
        element: <MonthlyProcessingPage />,
        permission: 'MONTHLYCOMMISSION',
      },
      {
        title: 'CME Commissions',
        path: 'cmecommissions',
        element: <CmeCommissionsPage />,
        permission: 'CMECOMMISSION',
      },
      {
        title: 'LRP Commissions',
        path: 'lrpcommissions',
        element: <LrpCommissionsPage />,
        permission: 'LRPCOMMISSION',
      },
      {
        title: 'Settings',
        path: 'settings',
        element: <SettingPage />,
        permission: 'SETTING',
      },
    ],
  },
];

import Iconify from 'src/components/iconify/iconify';
import useExportExcelMutation from 'src/compass/shared/hooks/reactQuery/useExportExcelMutation';
import { IcasPopoverOption } from 'src/compass/shared/components/CasPopoverMenu/CasPopoverMenu';

type useExportOptionProps = {
  entity?: string;
  params?: Record<string, any>;
  includeExport?: boolean;
};

interface ExportOptionResult {
  exportOption: IcasPopoverOption[];
}

export const useExportOption = ({
  entity = '',
  params,
  includeExport = true,
}: useExportOptionProps): ExportOptionResult => {
  const mutation = useExportExcelMutation({ entity, params });

  const { mutate, isPending } = mutation;

  const handleDownloadExcel = async () => mutate({});

  return {
    exportOption: includeExport
      ? [
          {
            label: (
              <>
                <Iconify icon="solar:export-bold" />
                Export
              </>
            ),
            onClick: handleDownloadExcel,
            disabled: isPending,
          },
        ]
      : [],
  };
};

import * as Yup from 'yup';
import getMaximumDecimalSchema from './getMaximumDecimalSchema';

export function getNullableIntegerSchema(fieldName: string) {
  return Yup.number()
    .nullable()
    .integer(`${fieldName} must not be decimal.`);
}

export default function getNumberSchema(
  customMessage: string,
  moreThanNumber: number = 0,
  maxDecimalPlaces: number = 2
) {
  return Yup.number()
    .concat(getMaximumDecimalSchema(maxDecimalPlaces))
    .typeError("Please enter a valid number")
    .required(`${customMessage} is required.`)
    .moreThan(moreThanNumber, `${customMessage} must be greater than $${moreThanNumber}.`);
}

// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
//
import CasErrorDisplay from '../CasError/CasErrorDisplay';

export default function CasTableError() {
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <CasErrorDisplay message="Error loading data" />
      </TableCell>
    </TableRow>
  );
}

import { forwardRef } from 'react';

// @mui
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

// ----------------------------------------------------------------------

export const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

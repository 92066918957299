import { Helmet } from 'react-helmet-async';
// sections
import { CasPageContent } from 'src/compass/shared/components';
import View500 from './components/View500';

export default function Page500() {
  return (
    <>
      <Helmet>
        <title> 500 Internal Server Error</title>
      </Helmet>
      <CasPageContent>
        <View500 />
      </CasPageContent>
    </>
  );
}

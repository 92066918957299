// @mui
import { CircularProgress, Backdrop } from '@mui/material';

export default function CasLoaderWithBackdrop({caption=null}:any) {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <div style={{textAlign: "center"}}>
        <CircularProgress color="primary" /> 
        {caption &&
          <div style={{color:"white", fontWeight:"bold"}}>
            {caption}
          </div>
        }
      </div>
    </Backdrop>
  );
}

import { ReactNode } from 'react';
import { Button, SxProps, Theme, ButtonProps } from '@mui/material';

export type ICasButton = ButtonProps & {
  startIcon?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children: string | ReactNode;
  variant?: 'contained' | 'text' | 'outlined' | 'soft';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | undefined;
  sx?: SxProps<Theme>;
}

export default function CasButton({
  startIcon,
  onClick = () => {},
  children,
  color = 'primary',
  variant = 'contained',
  disabled = false,
  size,
  sx,
  ...other
}: ICasButton) {
  return (
    <Button
      variant={variant}
      color={color}
      startIcon={startIcon}
      size={size}
      sx={sx}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      {children}
    </Button>
  );
}

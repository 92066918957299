import { Stack } from '@mui/material';
// types
import ICasRenderCellProps from '../ICasRenderCellProps';
import { CasRenderArrayAsRows } from './CasRenderArrayAsRows';
import CasRenderAsOfDate from './CasRenderAsOfDate';

/*
    Display as of date with its data.
*/

export function CasRenderAsOfArrayAsRows({ row, column }: ICasRenderCellProps) {
  return (
    <Stack>
      <CasRenderAsOfDate row={row} />
      <CasRenderArrayAsRows row={row} column={column} textAlign="right"/>
    </Stack>
  );
}

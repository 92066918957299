import formatCamelCaseAsTitle from 'src/compass/shared/util/formatCamelCaseAsTitle';

const fieldNameToLabelTranslation: Record<string, string> = {
  reinsuranceYear: 'CY',
  effectiveDate: 'Effective',
  endDate: 'End',
};

export default function defaultLabelFromField(field: string) {
  if (Object.prototype.hasOwnProperty.call(fieldNameToLabelTranslation, field))
    return fieldNameToLabelTranslation[field];
  return formatCamelCaseAsTitle(field);
}

import { useEffect, useState } from 'react';
import { m } from 'framer-motion';
import { useMsal } from '@azure/msal-react';
// @mui
import { Box, Divider, MenuItem, IconButton, Typography } from '@mui/material';

// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

type IUserInfo = {
  firstName?: string;
  name?: string;
  username?: string;
  email?: string;
};

export default function CasAccountPopover() {
  const { instance } = useMsal();
  const [user, setUser] = useState<IUserInfo | null>(null);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount && activeAccount.username !== user?.username) {
      setUser({
        firstName: activeAccount?.name?.split(' ')[0],
        name: activeAccount.name,
        username: activeAccount.username,
      });
    } else if (!activeAccount && user !== null) {
      setUser(null);
    }
  }, [activeAccount, user]);

  const popover = usePopover();

  const handleLogout = async () => {
    instance.logoutRedirect();
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          height: 40,
          fontSize: 16,
          fontWeight: 'bold',
          color: '#999',

          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        {user?.firstName}
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.username}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}

import { NavListProps, NavConfigProps } from "src/components/nav-section";

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
  RenderNavList: React.ElementType;
};

export default function CasNavSubList({ data, depth, config, RenderNavList }: NavListSubProps) {
  return (
    <>
      {data.map(
        (list) =>
          !list.hidden && (
            <RenderNavList
              key={list.title + list.path}
              data={list}
              depth={depth + 1}
              hasChild={!!list.children}
              config={config}
            />
          )
      )}
    </>
  );
}
/*
  If integer then return it otherwise 
  if decimal then return with upto 2 decimal places. 
*/

export default function formatWithConditionalDecimals(value: string) {
  const numberValue = Number(value);

  if (Number.isNaN(numberValue)) {
    return value; // Handle non-numeric input
  }

  return Number.isInteger(numberValue) ? numberValue : numberValue.toFixed(2);
}

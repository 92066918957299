import { TableCell } from '@mui/material';
import ICasTableColumn from '../ICasTableColumn';

interface ICasTableCell {
  row: any;
  column: ICasTableColumn;
}

export default function CasTableCell({ row, column }: ICasTableCell) {
  return (
    <TableCell
      sx={{
        whiteSpace: 'break-spaces',
        ...(column.sx ? column.sx(row, column) : {}),
      }}
    >
      {column.render ? column.render({ row, column }) : row?.[column.field]}
    </TableCell>
  );
}

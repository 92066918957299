import { Helmet } from 'react-helmet-async';
// sections
import { CasPageContent } from 'src/compass/shared/components';
import View503 from './components/View503';

export default function Page503() {
  return (
    <>
      <Helmet>
        <title> Service Unavailable</title>
      </Helmet>
      <CasPageContent>
        <View503 />
      </CasPageContent>
    </>
  );
}

import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
// @mui
import Typography from '@mui/material/Typography';
import { CasPageContent } from 'src/compass/shared/components';

export default function LogoutPage() {
  const { instance } = useMsal();

  useEffect(() => {
    if (instance) {
      instance.logoutRedirect();
    }
  }, [instance]);

  return (
    <>
      <Helmet>
        <title>Logout</title>
      </Helmet>

      <CasPageContent>
        <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>Logging out...</Typography>
      </CasPageContent>
    </>
  );
}

import { useMemo } from 'react';

// components
import {
  CasEditForm,
  CasRHFTextField,
  CasRHFSwitch,
  IEditForm,
} from 'src/compass/shared/components';
import { Entity } from 'src/compass/site/util';

// schema
import { EmployeeSchema } from './EmployeeSchema';
import UserSelect from './UserSelect';
import { RHFBranchSelect } from './BranchSelect';

// ----------------------------------------------------------------------

export default function EmployeeForm({ row, onSaved }: IEditForm) {
  const { id } = row || {};

  const defaultValues = useMemo(
    () => (id ? row : { isActive: true }),
    [id, row]
  );

  return (
    <CasEditForm
      defaultValues={defaultValues}
      onSaved={onSaved}
      entity={Entity.EMPLOYEE}
      schema={EmployeeSchema}
    >
      <UserSelect name="userId" label="User" />

      <CasRHFTextField name="shortName" label="Short Name" />

      <RHFBranchSelect name="branchId" label="Branch" placeholder="Branch name" />

      <CasRHFTextField name="docusignUser" label="Docusign User" placeholder="Docusign User" />
      <CasRHFTextField name="sceEntryCCEmail" label="SCE CC" placeholder="SCE CC" />
      <CasRHFTextField
        type="number"
        name="payrollEmpNbr"
        label="Payroll Key (Emp#)"
        placeholder="Payroll Key (Emp#)"
      />
      <CasRHFSwitch name="isRiskManager" label="Is Risk Manager?" />
      <CasRHFSwitch name="isSalesOrOther" label="Is Sales/Other?" />
      <CasRHFSwitch name="isActive" label="Active" />
    </CasEditForm>
  );
}

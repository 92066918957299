import { CasHeader } from "src/compass/site/layout";

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  return (
    <CasHeader onOpenNav={onOpenNav}/>
  );
}

import { getDateDuration } from "./casMoment";

export function relativeDateString(date: string) {
  // sending undefined will make dateString to get the today's date & time.
  const duration = getDateDuration(undefined, date)
  if (duration.years() === 1) return `a year ago`;
  if (duration.years() > 1) return `${duration.years()} years ago`;

  if (duration.months() === 1) return `a month ago`;
  if (duration.months() > 1) return `${duration.months()} months ago`;

  if (duration.weeks() === 1) return `a week ago`;
  if (duration.weeks() > 1) return `${duration.weeks()} weeks ago`;

  switch (duration.days()) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    default:
      return `${duration.days()} days ago`;
  }
}

import { InputAdornment } from '@mui/material';
import { CasRHFTextField } from '..';

export default function CasCurrencyField(props: any) {
  const labelLength = props?.label?.length ?? 0;
  return (
    <CasRHFTextField
      {...props}
      type="number"
      sx={{ maxWidth: Math.max(130, labelLength * 7), pl: 0 }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
}

import { createContext, useContext, useMemo, useState } from 'react';
import { IContextChildren } from '../util';

interface DialogProps {
  Component: React.ComponentType<any>;
  props: any;
}

const DialogContext = createContext({
  open: (Component: React.ComponentType<any>, props: any) => {},
});

export const useDialog = () => useContext(DialogContext);

const DialogContextProvider = ({ children }: IContextChildren) => {
  const [dialogState, setDialogState] = useState<DialogProps | null>(null);

  const onOpen = (Component: React.ComponentType<any>, props: any) => {
    setDialogState({ Component, props });
  };

  const onClose = () => setDialogState(null);

  const valueObj = useMemo(() => ({ open: onOpen }), []);

  return (
    <DialogContext.Provider value={valueObj}>
      {children}
      {dialogState && <dialogState.Component {...dialogState.props} onClose={onClose} />}
    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
import { useContext } from 'react';

import { AuthContext } from 'src/compass/site/providers';
import {
  AdministrationMenu,
  CommissionsMenu,
  EndorsementsMenu,
  RiskManagementMenu,
  UtilitiesMenu,
} from './menus';

export type SubMenu = {
  title: string;
  path: string;
  element: React.ReactElement;
  permission: string;
  hidden?: boolean;
};

export type Menu = {
  menuTitle: string;
  path: string;
  icon?: React.ReactElement;
  subMenus: SubMenu[];
};

const menus: Menu[] = [
  ...RiskManagementMenu,
  ...CommissionsMenu,
  ...UtilitiesMenu,
  ...EndorsementsMenu,
  ...AdministrationMenu,
];

function hasCrudPermission(userPermissions: string[], permission: string) {
  return ['C_', 'R_', 'U_', 'D_'].some((prefix) =>
    userPermissions.includes(`${prefix}${permission}`)
  );
}

export function useCasFilteredNavigation() {
  const { userPermissions } = useContext(AuthContext);

  const filteredMenu = menus
    .map((section) => ({
      ...section,
      subMenus: section.subMenus
        .filter((subMenu) => hasCrudPermission(userPermissions || [], subMenu.permission))
        .map((subMenu) => ({
          ...subMenu,
          path: `/${section.path}/${subMenu.path}`, // Concatenate base+ parent path with child path
        })),
    }))
    .filter((section) => section.subMenus.length > 0); // Filter out sections with no subMenus

  return filteredMenu;
}

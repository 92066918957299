import { m } from 'framer-motion';
import { Typography } from '@mui/material';

import { MotionContainer, varBounce } from 'src/components/animate';
import React from 'react';

type RenderErrorProps = {
  title: string;
  description?: React.ReactNode;
  illustration: React.ElementType;
  footer?: React.ReactNode;
};

export default function RenderError({
  title,
  description,
  illustration: Illustration,
  footer,
}: RenderErrorProps) {
  return (
    <MotionContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
      }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2, textAlign: 'center' }}>
          {title}
        </Typography>
      </m.div>

      {description && (
        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
            {description}
          </Typography>
        </m.div>
      )}

      <m.div variants={varBounce().in}>
        <Illustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      {footer}
    </MotionContainer>
  );
}

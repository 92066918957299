import * as Yup from 'yup';
import getMaximumDecimalSchema from './getMaximumDecimalSchema';

const MAXIMUM_MESSAGE = 'must not be more than 100%.';
const MINIMUM_MESSAGE = 'must be greater than 0%.';
const REQUIRED = 'is required.';

export default function getPercentSchema(
  customMessage: string,
  max: number = 100,
  maxDecimalPlaces: number = 3
) {
  return Yup.number()
    .concat(getMaximumDecimalSchema(maxDecimalPlaces))
    .typeError("Please enter a valid percentage")
    .required(`${customMessage} ${REQUIRED}`)
    .moreThan(0, `${customMessage} ${MINIMUM_MESSAGE}`)
    .max(max, `${customMessage} ${MAXIMUM_MESSAGE}`)
}

import { Stack, Box } from '@mui/material';
// types
import ICasRenderCellProps from '../ICasRenderCellProps';
import CasRenderAsOfDate from './CasRenderAsOfDate';

/*
    Display as of date with its data.
*/

export function CasRenderAsOfString({ row, column }: ICasRenderCellProps) {
  return (
    <Stack>
      <CasRenderAsOfDate row={row} />
      <Box textAlign="right">{row[column.field]}</Box>
    </Stack>
  );
}

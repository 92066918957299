import { useCallback, useState } from 'react';

// ----------------------------------------------------------------------

export interface useCasBooleanProps {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function useCasBoolean(defaultValue?: boolean): useCasBooleanProps {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  return {
    value,
    setTrue,
    setFalse,
    toggle,
    setValue,
  };
}

import { ReactNode } from 'react';
import { Stack, StackProps } from '@mui/system';
import { Typography } from '@mui/material';

interface BlockProps extends StackProps {
  label?: string;
  label2?: string | ReactNode;
  children: React.ReactNode;
  className?: string;
}

export default function Block({ label = 'RHFTextField', label2, sx, children, className }: BlockProps) {
  return (
    <Stack sx={sx} className={className}>
      <Typography
        variant="caption"
        sx={{
          fontSize: '12px',
          color: 'text.disabled',
          whiteSpace: 'nowrap'
        }}
      >
        {label}
      </Typography>
      {children}
      <Typography
        variant="caption"
        sx={{
          color: 'text.disabled',
        }}
      >
        {label2}
      </Typography>
    </Stack>
  );
}

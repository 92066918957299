import { relativeDateString } from 'src/compass/shared/util/relativeDateString';
// types
import ICasRenderCellProps from '../ICasRenderCellProps';

/*
 render relative date with the changed User.
*/

export function CasRenderRelativeDateWithName({ row, column }: ICasRenderCellProps) {
  return `${relativeDateString(row.changedDate)} \nby ${row.changedUser}`;
}

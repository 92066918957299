const MAX_BIGINT_64BIT = BigInt('9223372036854775807');

/**
 * Checks if the given bigint value exceeds the maximum allowed value.
 *
 * @param value - The bigint value to check.
 * @returns True if the value is greater than the maximum allowed bigint, otherwise false.
 */

export function isGreaterThanMaxBigInt(value: bigint): boolean {
  return value > MAX_BIGINT_64BIT;
}

/**
 * Validates and converts a given value to a bigint.
 *
 * @param value - The value to validate and convert.
 * @returns The valid bigint or 0 if the value is invalid.
 */

export function getValidBigInt(value: any): bigint | number {
  try {
    const bigIntValue = BigInt(value ?? 0);
    if (isGreaterThanMaxBigInt(bigIntValue)) return 0;
    return bigIntValue; // Return the valid BigInt
  } catch(error) {
    return 0;
  }
}

// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// components
// assets
import { PageNotFoundIllustration } from 'src/assets/illustrations';
import { useCasAuthenticated } from 'src/compass/shared/hooks/useUidParam';
import RenderError from './RenderError';

export default function View404() {
  const { isAuthenticated } = useCasAuthenticated();

  return (
    <RenderError
      title="Sorry, Page Not Found!"
      description="Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling."
      illustration={PageNotFoundIllustration}
      footer={
        isAuthenticated && (
          <Button component={RouterLink} href="/" size="large" variant="contained">
            Go to Home
          </Button>
        )
      }
    />
  );
}

import { formatDate, DateFormat, getDateInMoment } from 'src/compass/shared/util/casMoment';
import { usePeriodDates } from 'src/compass/shared/hooks/usePeriodDates';

export type useDateValuesProps = {
  numberOfMonths?: number;
  excludeDates?: string[];
  includeCustomOption?: boolean;
};

export const Custom_Label = 'Custom...';

export default function useFirstOfMonthDateValues({
  numberOfMonths = 6,
  excludeDates = [],
  includeCustomOption = false,
}: useDateValuesProps) {
  const { data } = usePeriodDates();
  const { currentPeriodStart } = data;

  if (!currentPeriodStart) return [];

  const dates = [];
  const exist = excludeDates?.some((item) => item === currentPeriodStart);
  if (!exist) dates.push(currentPeriodStart);

  let currentDate = getDateInMoment(currentPeriodStart);

  while (dates.length < numberOfMonths) {
    currentDate = currentDate.add(1, 'month').startOf('month');
    const value = formatDate(currentDate, DateFormat.YMD);

    if (!excludeDates?.some((item) => item === value)) dates.push(value);
  }

  if (includeCustomOption) dates.push(Custom_Label);

  return dates;
}

import { Box } from '@mui/material';
import { Theme, SxProps } from '@mui/material/styles';

type CasErrorDisplayProps = {
  message: string | JSX.Element;
  sx?: SxProps<Theme>;
}

function removeWrapQuotes(s: CasErrorDisplayProps['message']) {
  if (typeof s === 'string' || s instanceof String) {
    s = s.trim();
    if (s.startsWith('"') && s.endsWith('"')) return s.substring(1, s.length - 1);
  }

  return s;
}

export default function CasErrorDisplay({ message, sx }: CasErrorDisplayProps) {
  return <Box sx={{ color: 'red', fontWeight: 'bold', ...sx }}>{removeWrapQuotes(message)}</Box>;
}

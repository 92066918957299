import { type MRT_RowData, type MRT_TableOptions, type MRT_TableState } from 'material-react-table';

type MRT_OptionsProps = {
  isError: boolean;
  isLoading: boolean;
  grouping?: string[];
  initialState?: Partial<MRT_TableState<MRT_RowData>>;
};

// Check if the row is subRow or now
function isSubRow(row: any) {
  return row.depth > 0;
}

export const getDefaultMRTableOptions = <TData extends MRT_RowData>({
  isError,
  isLoading,
  grouping = [],
  initialState = {},
}: MRT_OptionsProps): Partial<MRT_TableOptions<TData>> => ({
  initialState: {
    showColumnFilters: false,
    density: 'compact',
    grouping,
    expanded: true,
    showGlobalFilter: true,
    ...(initialState as Partial<MRT_TableState<TData>>),
  },
  positionGlobalFilter: 'left',
  muiTablePaperProps: ({ table }) => ({
    style: {
      zIndex: table.getState().isFullScreen ? 1102 : undefined,
    },
  }),
  muiTableHeadRowProps: {
    sx: {
      boxShadow: 'none',
    },
  },
  muiTableHeadCellProps: {
    sx: {
      fontSize: '0.9rem',
      backgroundColor: '#F4F6F8',
      padding: '8px 12px',
      lineHeight: '16px',
      fontWeight: 600,
    },
  },
  muiTableBodyCellProps: {
    sx: {
      color: 'rgb(33, 43, 54)',
    },
  },
  muiTableBodyRowProps: ({ row }) => ({
    className: isSubRow(row) ? 'MRTable-subRow' : '',
  }),
  enableStickyHeader: true,
  enablePagination: false,
  enableDensityToggle: false,
  enableRowPinning: true,
  enableFacetedValues: true,
  rowPinningDisplayMode: 'select-sticky',
  enableGrouping: true,
  groupedColumnMode: 'remove',
  muiToolbarAlertBannerProps: isError
    ? {
        color: 'error',
        children: 'Error loading data',
      }
    : undefined,
  state: {
    isLoading,
    showAlertBanner: isError,
  },
});

import { Container } from '@mui/material';

export interface ICasPageContent {
  children: React.ReactNode;
  className?: string;
}

export default function CasPageContent({ children, ...rest }: ICasPageContent) {
  return (
    <Container maxWidth={false} sx={{ height: 'inherit' }} {...rest}>
      {children}
    </Container>
  );
}

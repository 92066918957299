/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Stack, Typography } from '@mui/material';
import CasButton from '../CasButton/CasButton';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

class CasErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.log(error);
    return { hasError: true };
  }

  resetErrorBoundary = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Stack alignItems="center" marginTop={2} gap={3}>
          <Typography variant="h4">Sorry, an error occurred</Typography>
          <CasButton onClick={this.resetErrorBoundary} >
            Reload
          </CasButton>
        </Stack>
      );
    }

    return children;
  }
}

export default CasErrorBoundary;

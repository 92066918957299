// @mui
import Button from '@mui/material/Button';
// assets
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import { paths } from 'src/compass/site/routes';
import { RouterLink } from 'src/routes/components';
import RenderError from './RenderError';

// ----------------------------------------------------------------------

export default function View503() {
  const handleRetryButtonClick = () => {
    window.location.href = paths.home;
  };

  return (
    <RenderError
      title="503 Service Unavailable"
      description="There was an error, please try again later."
      illustration={SeverErrorIllustration}
      footer={
        <Button
          component={RouterLink}
          size="large"
          variant="contained"
          onClick={handleRetryButtonClick}
        >
          Retry
        </Button>
      }
    />
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import CasTabs, { TabItem } from './_internal/CasTabs';
import CasTabsPanel from './_internal/CasTabsPanel';

// ----------------------------------------------------------------------

interface ICasTabsView {
  tabList: TabItem[];
  onTabChange?: (tab: string) => void;
  defaultActiveTab?: string | null;
  onActiveTabChange?: (activeTab: string) => void;
}

export default function CasTabsView({
  tabList,
  onTabChange,
  defaultActiveTab,
  onActiveTabChange,
}: ICasTabsView) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab ?? tabList[0].label);

  const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    if (onTabChange) onTabChange(newValue);
    if (onActiveTabChange) onActiveTabChange(newValue);
  }, []);

  return (
    <>
      <CasTabs value={activeTab} handleTabchange={handleTabChange} tabList={tabList} />
      {tabList.map(({ label, children }) => (
        <CasTabsPanel value={activeTab} index={label} key={label}>
          {children}
        </CasTabsPanel>
      ))}
    </>
  );
}

// types
import formatCurrency from 'src/compass/shared/util/formatCurrency';
import ICasRenderCellProps from '../ICasRenderCellProps';

/*
    Display label with value as amount.
*/

type IItem = {
  label: string;
  value: number;
};

export function CasRenderArrayOfLabelWithCurrency({ row, column }: ICasRenderCellProps) {
  return row[column.field]
    ?.map((item: IItem) => `${item.label}: ${formatCurrency(item.value)}`)
    .join(' \n');
}

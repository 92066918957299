import prependNoneOption from 'src/compass/shared/util/prependNoneOption';

import prependAllOption from '../../util/prependAllOption';
import { useRetrieveEntityList } from './useRetrieveEntityList';

export type EntityListOptionsProps = {
  includeAllOption?: boolean;
  includeNoneOption?: boolean;
};

type Props = EntityListOptionsProps & {
  entity: string;
};

export default function useRetrieveEntityListOptions({
  entity,
  includeAllOption = false,
  includeNoneOption = false,
}: Props) {
  const { data, isLoading } = useRetrieveEntityList({ entity });

  let options = data?.result || [];
  if (includeAllOption) options = prependAllOption(options);
  if (includeNoneOption) options = prependNoneOption(options);

  return { options, isLoading };
}

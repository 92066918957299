import { Box } from '@mui/material';
import formatCurrency from 'src/compass/shared/util/formatCurrency';
import { ICasMRTCellProps, ICasMRTAggregatorCellProps } from './ICasMRTCellProps';

export default function CasMRTCellRenderCurrency({
  cell,
}: ICasMRTCellProps | ICasMRTAggregatorCellProps) {
  const value = cell.getValue();
  return value !== null && <Box textAlign="right">{formatCurrency(Number(value))}</Box>;
}

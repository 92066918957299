import useCasMutation, {
  GenericMutationProps,
} from 'src/compass/shared/hooks/reactQuery/useCasMutation';

type uploadMutationProps = GenericMutationProps & {
  url: string;
};

export default function useDeleteMutation({ url, ...rest }: uploadMutationProps) {
  return useCasMutation({ method: 'delete', url, ...rest });
}

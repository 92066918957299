import { ReactNode } from 'react';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// components
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify/iconify';

export interface IcasPopoverOption {
  label: string | ReactNode;
  onClick: () => {};
  disabled?: boolean;
}

interface ICasPopOverMenu {
  options: IcasPopoverOption[];
}

const CasPopoverMenu = ({ options }: ICasPopOverMenu) => {
  const popover = usePopover();

  return (
    <>
      <IconButton onClick={popover.onOpen}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {options.map((option: IcasPopoverOption, index: number) => (
          <MenuItem
            key={index}
            onClick={() => {
              popover.onClose();
              option.onClick();
            }}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
};

export default CasPopoverMenu;

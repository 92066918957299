import * as Yup from 'yup';

const MAXIMUM_LIMIT_MESSAGE = 'Your input exceeds the maximum length of 50 characters.';

export default function getEmailSchema(customMessage: string = 'Email') {
  return (
    Yup.string()
      .trim()
      .email('Invalid email')
      .required(`${customMessage} is required`)
      .max(50, MAXIMUM_LIMIT_MESSAGE)
      // Browser doesn't flag entry without period as error.  e.g. joe@gmail
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, { message: 'Invalid email', excludeEmptyString: true })
  );
}

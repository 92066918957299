import Iconify from 'src/components/iconify/iconify';
import ICasRenderCellProps from '../ICasRenderCellProps';

interface ICasBooleanIcon {
  value: boolean;
  falseIcon: boolean;
}

// This component may be useful directly - but isn't currently used directly
export function CasBooleanIcon({ value, falseIcon }: ICasBooleanIcon) {
  if (value) return <Iconify icon="icon-park-solid:correct" width={20} color="#808080" />;
  if (falseIcon && value === false)
    return <Iconify icon="mdi:close-thick" width={20} color="error.main" />;
  return null
}

export function CasRenderBoolTrue({ row, column }: ICasRenderCellProps) {
  return <CasBooleanIcon value={row[column.field]} falseIcon={false} />;
}

export function CasRenderBoolTrueFalse({ row, column }: ICasRenderCellProps) {
  return <CasBooleanIcon value={row[column.field]} falseIcon />;
}

import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

interface CasSwitchProps extends Omit<FormControlLabelProps, 'control' | 'onChange'> {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  helperText?: React.ReactNode;
}

export default function CasSwitch({ checked, onChange, helperText, ...other }: CasSwitchProps) {
  return (
    <div>
      <FormControlLabel control={<Switch checked={checked} onChange={onChange} />} {...other} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
}

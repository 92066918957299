import { lazy } from 'react';
import { ICONS } from '../navigationIcons';

const ScePage = lazy(() => import('src/compass/pages/endorsements/SceEntryPage'));
const SceOrdersPage = lazy(() => import('src/compass/pages/endorsements/SceOrdersPage'));
const LrpQuotesPage = lazy(() => import('src/compass/pages/endorsements/LrpQuotesPage'));

export const EndorsementsMenu = [
  {
    menuTitle: 'Endorsements',
    path: 'endorsements',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'SCE Entry',
        path: 'sce',
        element: <ScePage />,
        permission: 'SCEORDERS',
        hidden: true,
      },
      {
        title: 'SCE Orders',
        path: 'sceorders',
        element: <SceOrdersPage />,
        permission: 'SCEORDERS',
      },
      {
        title: 'LRP Quotes',
        path: 'lrpquotes',
        element: <LrpQuotesPage />,
        permission: 'LRP_QUOTES',
      },
    ],
  },
];

import { Entity } from 'src/compass/site/util';
import prependAllOption from '../util/prependAllOption';
import { useRetrieveEntityList } from './reactQuery/useRetrieveEntityList';

// get listing date for options when there is no payload

export function useGetEntityOptions(entity: Entity, includeAllOption?: boolean) {
  const { data, ...rest } = useRetrieveEntityList({
    entity,
    params: {
      isActive: true,
      pageNumber: 1,
      pageSize: 100,
      sortColumn: 'name',
      sortOrder: 'asc',
    },
    onSuccess: (response: any) =>
      response?.result?.map(({id, name}:any) => ({id, name })),
  });

  const selectOptions = includeAllOption ? prependAllOption(data ?? []) : data ?? []

  return { data: selectOptions, ...rest };
}

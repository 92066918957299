import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import CasLabel from '../CasLabel/CasLabel';

// ----------------------------------------------------------------------

type Props = RadioGroupProps & {
  name: string;
  options: {
    label: string | React.ReactNode;
    value: any;
    propertyNameToClear?: string;
    displayOuterError?: boolean;
    component?: JSX.Element;
  }[];
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
  disabled?: boolean;
};

export default function CasRadioGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  disabled,
  ...other
}: Props) {
  const { control, setValue, clearErrors } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setValue(name, selectedValue, { shouldValidate: true });
    options.forEach((option: any) => {
      if (option?.propertyNameToClear && option?.propertyNameToClear !== selectedValue) {
        clearErrors(option?.propertyNameToClear);
      } else if (option.label?.props?.name && option.label?.props?.name !== selectedValue) {
        clearErrors(option.label?.props.name);
      }
    });
  };

  return (
    <CasLabel label={label}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl component="fieldset" disabled={disabled}>
            <RadioGroup {...field} value={field.value} row={row} {...other} onChange={handleChange}>
              {options.map((option) => (
                <Box>
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio checked={option.value === field.value} />}
                    label={<div style={{paddingTop:8}}>{option.label}</div>}
                    sx={{
                      '&:not(:last-of-type)': {
                        mb: spacing || 0,
                      },
                      ...(row && {
                        mr: 0,
                        '&:not(:last-of-type)': {
                          mr: spacing || 2,
                        },
                      }),                      
                      alignItems: 'flex-start',
                    }}
                  />
                  {option?.propertyNameToClear &&
                    option.value === field.value && (
                      <Controller
                        name={option.propertyNameToClear}
                        control={control}
                        defaultValue=""
                        render={({ fieldState: { error: componentError } }) => (
                          <>
                            {option?.displayOuterError &&
                              <FormHelperText error={!!componentError} sx={{ mx: 0 }}>
                                {componentError ? componentError?.message : helperText}
                              </FormHelperText>
                            }
                            {option?.component && <>{option.component}</>}
                          </>
                        ) || null
                        }
                      />
                    )}
                </Box>
              ))}
            </RadioGroup>

            {(!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ mx: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </CasLabel>
  );
}

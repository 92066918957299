import './_lrpQuotes/styles/lrpQuotes.scss';

import { CasPage } from 'src/compass/shared/components';
import useRetrieve from 'src/compass/shared/hooks/reactQuery/useRetrieve';
import { DateFormat, formatDate } from 'src/compass/shared/util/casMoment';
import { LrpQuotesContainer } from './_lrpQuotes/components';

const getLRPQuotesTitle = (date: string) => {
  if (!date) return 'LRP Quotes';
  const formattedDate = formatDate(date, DateFormat.M_D_YY_ddd);
  return `LRP Quotes: ${formattedDate}`;
};

export default function LrpQuotesPage() {
  const queryResult = useRetrieve({ url: '/LRPQuotes' });
  const { data } = queryResult;
  const { salesEffectiveDate } =
    data?.result?.find((tabData: any) => tabData.salesEffectiveDate) || {};

  return (
    <CasPage
      title={getLRPQuotesTitle(salesEffectiveDate)}
      pageTitle="Endorsements"
      className="lrpQuotesPage"
    >
      <LrpQuotesContainer queryResult={queryResult} salesEffectiveDate={salesEffectiveDate} />
    </CasPage>
  );
}

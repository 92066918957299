import { Helmet } from 'react-helmet-async';
import View404 from 'src/compass/pages/error/components/View404';

export default function LrpQuotesPage404() {
  return (
    <>
      <Helmet>
        <title> 404 Page Not Found!</title>
      </Helmet>
      <View404 />
    </>
  );
}

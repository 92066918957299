import { Switch } from '@mui/material';
import CasLabel from '../CasLabel/CasLabel';

interface ICasDisplaySwitch {
  label: string;
  value: boolean;
  className?: string
}

export default function CasDisplaySwitch({ label, value, className }: ICasDisplaySwitch) {
  return (
    <CasLabel label={label} className={className} >
      <Switch inputProps={{ 'aria-label': label }} checked={value} disabled />
    </CasLabel>
  );
}

import { useLayoutEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';
// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// image
import 'react-lazy-load-image-component/src/effects/blur.css';
// routes
import { CasRouter } from 'src/compass/site/routes';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import { CasContextProviders } from 'src/compass/site/providers';

let error_Navigate: NavigateFunction = () => {};

const setErrorNavigate = (nav: NavigateFunction) => {
  error_Navigate = nav;
};

export const getErrorNavigate = () => error_Navigate;

export default function App() {
  useScrollToTop();
  const navigate = useNavigate()

  /* 
  Assign navigate function to errorNavigate, for using it in onErrorHandlers
   Otherwise we have to pass navigate to onErrorHandler from multiple locations.
  */
  useLayoutEffect(() => {
    setErrorNavigate(navigate);
  }, [navigate]);

  return (
    <CasContextProviders>
      <CasRouter />
    </CasContextProviders>
  );
}

import { useAPI } from './useAPI';

interface IUseRetrieveDetailsById {
  entity: string;
  id: string | number;
  staleTime?: number;
  enabled?: boolean;
  onSuccess?: Function;
  queryKey?: string[];
}

export function useRetrieveDetailsById({
  entity,
  id,
  staleTime = 0,
  enabled = true,
  onSuccess,
  queryKey,
}: IUseRetrieveDetailsById) {

  return useAPI({
    method: 'get',
    url: `${entity}/${id}`,
    staleTime,
    enabled,
    onSuccess,
    queryKey,
  });
}

import { type MRT_RowData } from 'material-react-table';
import defaultLabelFromField from './defaultLabelFromField';

export default function generateColumnsFromData(
  data: MRT_RowData[],
  overrideColumns: Record<string, Record<string, any>> = {}
) {
  if (!data || !data.length) return [];

  const excludedKeys = ['id', 'subRows'];

  const keys = Object.keys(data[0]).filter((key: string) => !excludedKeys.includes(key));

  const columns = keys.map((key) => {
    const column = {
      accessorKey: key,
      header: defaultLabelFromField(key),
      filterFn: 'fuzzy',
    };

    // we will apply overRide properties if exist for that key.
    if (overrideColumns && overrideColumns[key]) {
      return {
        ...column,
        ...overrideColumns[key],
      };
    }

    return column;
  });
  return columns;
}
